.image--pulsing {
  animation: pulse 1s infinite ease-in-out;
  min-height: 150px;
}

.text--pulsing {
  animation: pulse 1s infinite ease-in-out;
  height: 10px;
  color: transparent !important;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@tailwind base;
@layer base {
  body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-md;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }
  button span {
    text-transform: uppercase !important;
  }
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply font-bold;
  }
  h5 {
    @apply text-sm font-bold;
  }
  label {
    @apply first-letter:uppercase;
  }
  p {
    @apply text-sm;
  }
  small {
    @apply text-xs;
  }
  .grid-cols-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.ql-editor {
  padding: 4px 8px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: black;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

quill-editor {
  @apply h-40 w-full max-w-full;
}

.FIE_topbar {
  display: none !important;
}

@tailwind components;
@layer components {
  .tag-box-option {
    @apply flex items-center border border-gray-200 text-gray-700 rounded-md text-sm font-medium py-0.5 px-2 hover:cursor-pointer;
  }
  .tag-box {
    @apply tag-box-option hover:cursor-auto bg-gray-200 text-black;
  }
  .tag-box__selected {
    @apply bg-green-200 border-green-200 text-black;
  }
  .anchor[href] {
    @apply underline text-blue-600 hover:text-blue-800;
  }
  .item--boxed {
    @apply rounded-lg bg-white p-4 shadow-sm;
  }
  .section-header {
    @apply text-gray-700 sticky p-6 bg-slate-100 z-30 top-0;
  }
  .section-header h2 {
    @apply text-gray-700 flex items-center first-letter:uppercase;
  }
  .section-header h2 span {
    @apply first-letter:uppercase;
  }
  .section-header .btns {
    @apply flex justify-end;
  }
  label.required::after {
    content: " *";
  }
  .entity-key-value-container__editable {
    @apply transition-all duration-100 ease-in;
  }
  .entity-key-value-container__editable .action-btn {
    @apply mt-3 text-gray-600 invisible;
  }
  .entity-key-value-container__editable:hover {
    @apply drop-shadow-lg;
  }
  .entity-key-value-container__editable:hover .action-btn {
    @apply visible;
  }
  .entity-key-value-container {
    @apply px-4 pb-4 border border-solid border-gray-300 rounded-lg self-start bg-white;
  }
  .entity-key-value-container .main-title {
    @apply first-letter:uppercase text-lg;
  }
  .entity-key-value-container .sub-title {
    @apply first-letter:uppercase font-medium text-gray-700;
  }
  .entity-key-value-container .key-value-record {
    @apply flex flex-col;
  }
  .entity-key-value-container .key-value-record .key {
    @apply first-letter:uppercase text-gray-500;
  }
}
@tailwind utilities;
@layer utilities {
  .tag {
    @apply bg-slate-300 px-2 py-1 rounded-full;
  }
  .clickable {
    @apply opacity-80 hover:opacity-100 hover:cursor-pointer transition-all;
  }
  .fieldset {
    @apply gap-4 flex sm:flex-row flex-col p-6 border w-full border-gray-200 rounded-md;
  }
  .fieldset .field-label {
    @apply first-letter:uppercase font-medium text-gray-800 mb-4;
  }
  .fieldset aside {
    @apply sm:w-1/3 w-full;
  }
  .fieldset main {
    @apply sm:w-2/3 w-full;
  }
  .v-ellipsis-1 .ql-editor {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .mention-list-container {
    background-color: #fff !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08) !important;
    overflow: auto !important;
    width: min(300px, 100vw);
    z-index: 9001 !important;
  }
}
.error-container {
  background: var(--mat-sys-error-container);
  color: var(--mat-sys-on-error-container);
}

.side-entry {
  display: block;
  background: #fff;
  animation: dialog-enter 0.3s ease;
  height: 100%;
  overflow-y: auto;
}

@keyframes dialog-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: none;
  }
}
html {
  color-scheme: light dark;
  border: 1px solid var(--mat-sys-outline-variant);
  font: var(--mat-sys-body-large);
}
html {
  --mat-sys-background: #fcf8fd;
  --mat-sys-error: #ba1a1a;
  --mat-sys-error-container: #ffdad6;
  --mat-sys-inverse-on-surface: #f3eff4;
  --mat-sys-inverse-primary: #bec2ff;
  --mat-sys-inverse-surface: #303034;
  --mat-sys-on-background: #1b1b1f;
  --mat-sys-on-error: #ffffff;
  --mat-sys-on-error-container: #93000a;
  --mat-sys-on-primary: #ffffff;
  --mat-sys-on-primary-container: #0000ef;
  --mat-sys-on-primary-fixed: #00006e;
  --mat-sys-on-primary-fixed-variant: #0000ef;
  --mat-sys-on-secondary: #ffffff;
  --mat-sys-on-secondary-container: #444559;
  --mat-sys-on-secondary-fixed: #191a2c;
  --mat-sys-on-secondary-fixed-variant: #444559;
  --mat-sys-on-surface: #1b1b1f;
  --mat-sys-on-surface-variant: #46464f;
  --mat-sys-on-tertiary: #ffffff;
  --mat-sys-on-tertiary-container: #245100;
  --mat-sys-on-tertiary-fixed: #0b2000;
  --mat-sys-on-tertiary-fixed-variant: #245100;
  --mat-sys-outline: #777680;
  --mat-sys-outline-variant: #c7c5d0;
  --mat-sys-primary: #343dff;
  --mat-sys-primary-container: #e0e0ff;
  --mat-sys-primary-fixed: #e0e0ff;
  --mat-sys-primary-fixed-dim: #bec2ff;
  --mat-sys-scrim: #000000;
  --mat-sys-secondary: #5c5d72;
  --mat-sys-secondary-container: #e1e0f9;
  --mat-sys-secondary-fixed: #e1e0f9;
  --mat-sys-secondary-fixed-dim: #c5c4dd;
  --mat-sys-shadow: #000000;
  --mat-sys-surface: #fcf8fd;
  --mat-sys-surface-bright: #fcf8fd;
  --mat-sys-surface-container: #f0edf1;
  --mat-sys-surface-container-high: #ebe7eb;
  --mat-sys-surface-container-highest: #e5e1e6;
  --mat-sys-surface-container-low: #f6f2f7;
  --mat-sys-surface-container-lowest: #ffffff;
  --mat-sys-surface-dim: #dcd9dd;
  --mat-sys-surface-tint: #343dff;
  --mat-sys-surface-variant: #e4e1ec;
  --mat-sys-tertiary: #326b00;
  --mat-sys-tertiary-container: #82ff10;
  --mat-sys-tertiary-fixed: #82ff10;
  --mat-sys-tertiary-fixed-dim: #70e000;
  --mat-sys-neutral-variant20: #303038;
  --mat-sys-neutral10: #1b1b1f;
}
html {
  --mat-sys-level0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-level1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-level2: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-level3: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-level4: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-level5: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mat-sys-body-large: 400 1rem / 1.5rem Roboto;
  --mat-sys-body-large-font: Roboto;
  --mat-sys-body-large-line-height: 1.5rem;
  --mat-sys-body-large-size: 1rem;
  --mat-sys-body-large-tracking: 0.031rem;
  --mat-sys-body-large-weight: 400;
  --mat-sys-body-medium: 400 0.875rem / 1.25rem Roboto;
  --mat-sys-body-medium-font: Roboto;
  --mat-sys-body-medium-line-height: 1.25rem;
  --mat-sys-body-medium-size: 0.875rem;
  --mat-sys-body-medium-tracking: 0.016rem;
  --mat-sys-body-medium-weight: 400;
  --mat-sys-body-small: 400 0.75rem / 1rem Roboto;
  --mat-sys-body-small-font: Roboto;
  --mat-sys-body-small-line-height: 1rem;
  --mat-sys-body-small-size: 0.75rem;
  --mat-sys-body-small-tracking: 0.025rem;
  --mat-sys-body-small-weight: 400;
  --mat-sys-display-large: 400 3.562rem / 4rem Open Sans;
  --mat-sys-display-large-font: Open Sans;
  --mat-sys-display-large-line-height: 4rem;
  --mat-sys-display-large-size: 3.562rem;
  --mat-sys-display-large-tracking: -0.016rem;
  --mat-sys-display-large-weight: 400;
  --mat-sys-display-medium: 400 2.812rem / 3.25rem Open Sans;
  --mat-sys-display-medium-font: Open Sans;
  --mat-sys-display-medium-line-height: 3.25rem;
  --mat-sys-display-medium-size: 2.812rem;
  --mat-sys-display-medium-tracking: 0;
  --mat-sys-display-medium-weight: 400;
  --mat-sys-display-small: 400 2.25rem / 2.75rem Open Sans;
  --mat-sys-display-small-font: Open Sans;
  --mat-sys-display-small-line-height: 2.75rem;
  --mat-sys-display-small-size: 2.25rem;
  --mat-sys-display-small-tracking: 0;
  --mat-sys-display-small-weight: 400;
  --mat-sys-headline-large: 400 2rem / 2.5rem Open Sans;
  --mat-sys-headline-large-font: Open Sans;
  --mat-sys-headline-large-line-height: 2.5rem;
  --mat-sys-headline-large-size: 2rem;
  --mat-sys-headline-large-tracking: 0;
  --mat-sys-headline-large-weight: 400;
  --mat-sys-headline-medium: 400 1.75rem / 2.25rem Open Sans;
  --mat-sys-headline-medium-font: Open Sans;
  --mat-sys-headline-medium-line-height: 2.25rem;
  --mat-sys-headline-medium-size: 1.75rem;
  --mat-sys-headline-medium-tracking: 0;
  --mat-sys-headline-medium-weight: 400;
  --mat-sys-headline-small: 400 1.5rem / 2rem Open Sans;
  --mat-sys-headline-small-font: Open Sans;
  --mat-sys-headline-small-line-height: 2rem;
  --mat-sys-headline-small-size: 1.5rem;
  --mat-sys-headline-small-tracking: 0;
  --mat-sys-headline-small-weight: 400;
  --mat-sys-label-large: 500 0.875rem / 1.25rem Roboto;
  --mat-sys-label-large-font: Roboto;
  --mat-sys-label-large-line-height: 1.25rem;
  --mat-sys-label-large-size: 0.875rem;
  --mat-sys-label-large-tracking: 0.006rem;
  --mat-sys-label-large-weight: 500;
  --mat-sys-label-large-weight-prominent: 800;
  --mat-sys-label-medium: 500 0.75rem / 1rem Roboto;
  --mat-sys-label-medium-font: Roboto;
  --mat-sys-label-medium-line-height: 1rem;
  --mat-sys-label-medium-size: 0.75rem;
  --mat-sys-label-medium-tracking: 0.031rem;
  --mat-sys-label-medium-weight: 500;
  --mat-sys-label-medium-weight-prominent: 800;
  --mat-sys-label-small: 500 0.688rem / 1rem Roboto;
  --mat-sys-label-small-font: Roboto;
  --mat-sys-label-small-line-height: 1rem;
  --mat-sys-label-small-size: 0.688rem;
  --mat-sys-label-small-tracking: 0.031rem;
  --mat-sys-label-small-weight: 500;
  --mat-sys-title-large: 400 1.375rem / 1.75rem Open Sans;
  --mat-sys-title-large-font: Open Sans;
  --mat-sys-title-large-line-height: 1.75rem;
  --mat-sys-title-large-size: 1.375rem;
  --mat-sys-title-large-tracking: 0;
  --mat-sys-title-large-weight: 400;
  --mat-sys-title-medium: 500 1rem / 1.5rem Roboto;
  --mat-sys-title-medium-font: Roboto;
  --mat-sys-title-medium-line-height: 1.5rem;
  --mat-sys-title-medium-size: 1rem;
  --mat-sys-title-medium-tracking: 0.009rem;
  --mat-sys-title-medium-weight: 500;
  --mat-sys-title-small: 500 0.875rem / 1.25rem Roboto;
  --mat-sys-title-small-font: Roboto;
  --mat-sys-title-small-line-height: 1.25rem;
  --mat-sys-title-small-size: 0.875rem;
  --mat-sys-title-small-tracking: 0.006rem;
  --mat-sys-title-small-weight: 500;
}
html {
  --mat-sys-corner-extra-large: 28px;
  --mat-sys-corner-extra-large-top: 28px 28px 0 0;
  --mat-sys-corner-extra-small: 4px;
  --mat-sys-corner-extra-small-top: 4px 4px 0 0;
  --mat-sys-corner-full: 9999px;
  --mat-sys-corner-large: 16px;
  --mat-sys-corner-large-end: 0 16px 16px 0;
  --mat-sys-corner-large-start: 16px 0 0 16px;
  --mat-sys-corner-large-top: 16px 16px 0 0;
  --mat-sys-corner-medium: 12px;
  --mat-sys-corner-none: 0;
  --mat-sys-corner-small: 8px;
}
html {
  --mat-sys-dragged-state-layer-opacity: 0.16;
  --mat-sys-focus-state-layer-opacity: 0.12;
  --mat-sys-hover-state-layer-opacity: 0.08;
  --mat-sys-pressed-state-layer-opacity: 0.12;
}
html .red-button {
  --mdc-filled-button-container-color: var(--mat-sys-error-container);
}
html .red-button {
  --mdc-filled-button-label-text-color: var(--mat-sys-on-error-container);
}

html {
  --mat-divider-color: var(--mat-sys-outline-variant);
  --mat-table-row-item-outline-color: var(--mat-sys-outline-variant);
  --mat-sys-surface: white;
  --mat-sys-background: white;
}

.mat-button-sm {
  --mdc-text-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-filled-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-protected-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-outlined-button-container-height: 24px;
}
.mat-button-sm {
  --mat-text-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-filled-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-protected-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-outlined-button-touch-target-display: none;
}

.mat-icon-button-sm {
  --mat-icon-button-touch-target-display: none;
}
.mat-icon-button-sm .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

.compact-text-field {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
.compact-text-field {
  --mat-select-arrow-transform: none;
}

.medium-text-field {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}
.medium-text-field {
  --mat-select-arrow-transform: none;
}

.dense-list {
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
}
.dense-list {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}
.dense-list .mdc-list-item__start,
.dense-list .mdc-list-item__end {
  --mdc-radio-state-layer-size: 28px;
}
.dense-list .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
.dense-list .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}

.mat-chip-sm .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.mat-chip-xs .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}