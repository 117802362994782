@tailwind utilities;

@layer utilities {
  .tag {
    @apply bg-slate-300 px-2 py-1 rounded-full;
  }
  .clickable {
    @apply opacity-80 hover:opacity-100 hover:cursor-pointer transition-all;
  }
  .fieldset {
    .field-label {
      @apply first-letter:uppercase font-medium text-gray-800 mb-4;
    }
    aside {
      @apply sm:w-1/3 w-full;
    }
    main {
      @apply sm:w-2/3 w-full;
    }
    @apply gap-4 flex sm:flex-row flex-col p-6 border w-full border-gray-200 rounded-md;
  }
  .circle {}

  .v-ellipsis-1 {
    .ql-editor {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

  }
  .mention-list-container {
    background-color: #fff!important;
    border: 1px solid #f0f0f0!important;
    border-radius: 4px!important;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, .08)!important;
    overflow: auto!important;
    width: min(300px,100vw);
    z-index: 9001!important;
  }
  
}
