@tailwind components;
@layer components {
    .tag-box-option {
      @apply flex items-center border border-gray-200 text-gray-700 rounded-md text-sm font-medium py-0.5 px-2 hover:cursor-pointer;
    }
  
    .tag-box {
      @apply tag-box-option hover:cursor-auto bg-gray-200 text-black;
    }
  
    .tag-box__selected {
      @apply bg-green-200 border-green-200 text-black;
    }
  
    .anchor[href] {
      @apply underline text-blue-600 hover:text-blue-800;
    }
  
    .item--boxed {
      @apply rounded-lg bg-white p-4 shadow-sm;
    }
  
    .section-header {
      @apply text-gray-700 sticky p-6 bg-slate-100 z-30 top-0;
  
      h2 {
        @apply text-gray-700 flex items-center first-letter:uppercase;
  
        span {
          @apply first-letter:uppercase;
        }
      }
  
      .btns {
        @apply flex justify-end;
      }
  
  
    }
    label.required::after {
      content:" *";
    }
    .entity-key-value-container__editable {
      @apply transition-all duration-100 ease-in;
  
      .action-btn {
        @apply mt-3 text-gray-600 invisible;
      }
    }
  
    .entity-key-value-container__editable:hover {
      @apply drop-shadow-lg;
  
      .action-btn {
        @apply visible;
      }
    }
  
    .entity-key-value-container {
      @apply px-4 pb-4 border border-solid border-gray-300 rounded-lg self-start bg-white;
  
      .main-title {
        @apply first-letter:uppercase text-lg;
      }
  
      .sub-title {
        @apply first-letter:uppercase font-medium text-gray-700;
      }
  
      .key-value-record {
        @apply flex flex-col;
  
        .key {
          @apply first-letter:uppercase text-gray-500;
        }
      }
    }
  }