.side-entry {
  display: block;
  background: #fff;
  animation: dialog-enter 0.3s ease;
  height: 100%;
  overflow-y: auto;
}

@keyframes dialog-enter {
 from {
  transform: translateX(100%);
 } 
 to {
  transform: none;
 }
}
