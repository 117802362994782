@use '@angular/material' as mat;

.mat-button-sm {
  @include mat.button-density(-3);
}
.mat-icon-button-sm {
  @include mat.icon-button-density(-2);
}

.compact-text-field {
  @include mat.form-field-density(-5);
  @include mat.select-density(-5);
}
.medium-text-field {
  @include mat.form-field-density(-2);
  @include mat.select-density(-2);
}
.dense-list {
  @include mat.list-density(-3);
}
.mat-chip-sm {
  @include mat.chips-density(-3)
}
.dense-icon {
  @include mat.icon-density(-6);
}


.mat-icon-sm {
  @include mat.icon-density(-3);
}
