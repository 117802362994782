@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@include mat.core();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$config: (
  (
    color: (
      primary: mat.$blue-palette,
      tertiary: mat.$chartreuse-palette,
    ),
    typography: (    
      brand-family: 'Roboto',
      bold-weight: 900),
    density: (
        scale: 0
      )
  )
);
// Merge the Material theme with Material Extensions theme
$theme: mat.private-deep-merge-all(
  mat.define-theme($config),
  mtx.define-theme($config)
);
html {
  @include mat.all-component-themes($theme);
  @include mtx.all-component-themes($theme);

  //@include mat.color-variants-backwards-compatibility($theme);
}


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


// .font--accent {
//   color: mat.get-color-from-palette($doctorus-app-accent);
// }

// .font--primary {
//   color: mat.get-color-from-palette($doctorus-app-primary);
// }

// .font--dark-primary {
//   color: mat.get-color-from-palette($doctorus-app-primary, 700) !important;
// }

// .font--warn {
//   color: mat.get-color-from-palette($doctorus-app-warn);
// }

// .primary-bg-color {
//   background-color: mat.get-color-from-palette($doctorus-app-primary);
// }

// .primary-light-bg-color {
//   background-color: mat.get-color-from-palette($doctorus-app-primary, 50);
// }

// .accent-bg-color {
//   background-color: mat.get-color-from-palette($doctorus-app-accent);
// }

// .accent-bg-color--darker {
//   background-color: mat.get-color-from-palette($doctorus-app-accent, darker);
// }

// .warn-bg-color {
//   background-color: mat.get-color-from-palette($doctorus-app-warn);
// }
.mat-icon-sm {
  @include mat.icon-density(-6);
}
