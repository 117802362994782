.image--pulsing {
  animation: pulse 1s infinite ease-in-out;
  min-height: 150px;
}
.text--pulsing {
  animation: pulse 1s infinite ease-in-out;
  height: 10px;
  color: transparent !important;
}
@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
